import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';

import { ClientSettingsService } from '../services/client-settings/client-settings.service';
import { PaperlessBillingService } from '../services/paperless-billing/paperless-billing.service';
import { SvgComponent } from '../svg/svg.component';

@Component({
  selector: 'app-banner',
  standalone: true,
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss',
  imports: [CommonModule, A11yModule, SvgComponent],
})
export class BannerComponent {
  paperlessBillingService = inject(PaperlessBillingService);
  clientSettingsService = inject(ClientSettingsService);

  constructor() {}

  openModal() {
    this.paperlessBillingService.openModal();
  }
}
