import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

export interface clientPaperlessSettings {
  offerEmail: boolean;
  offerText: boolean;
}

export interface paperlessBillingResponse {
  isEnabled: boolean;
  enrollmentState: string;
  emailSettings: clientPaperlessSettings;
  communicationPreference: string;
  currentEmail: string;
  emailIndicator: string;
  currentMobile: string;
  mobileIndicator: string;
  streetAddress1: string;
  streetAddress2: string;
  zip: string;
  city: string;
  state: string;
}

export interface paperlessBillingEnrollmentResponse {
  success: boolean;
  error: string;
}

export interface cityStateResponse {
  success: boolean;
  state: string;
  city: string;
}

@Injectable({
  providedIn: 'root',
})
export class PaperlessBillingService {
  endPointBase: string = '/PaperlessBilling/PaperlessBillingInfo';
  endpointUnenrollBase: string = '/PaperlessBilling/Unenroll';
  endPointEnrollBase: string = '/PaperlessBilling/Enroll';
  endPointEmailEnrollBase: string = '/PaperlessBilling/EnrollWithEmail';
  endPointPhoneEnrollBase: string = '/PaperlessBilling/EnrollWithMobile';
  endPointFoundationEnrollBase: string =
    '/PaperlessBilling/EnrollWithFoundation';
  endPointCityStateBase: string = '/PaperlessBilling/GetCityStateInfo';

  response: paperlessBillingResponse = {
    isEnabled: false,
    enrollmentState: '',
    emailSettings: { offerEmail: false, offerText: false },
    communicationPreference: '',
    currentEmail: '',
    emailIndicator: '',
    currentMobile: '',
    mobileIndicator: '',
    streetAddress1: '',
    streetAddress2: '',
    zip: '',
    city: '',
    state: '',
  };

  enrollmentResponse: paperlessBillingEnrollmentResponse = {
    success: false,
    error: '',
  };

  cityStateResponse: cityStateResponse = {
    success: false,
    state: '',
    city: '',
  };

  complete: boolean = false;

  private modalStateSource = new BehaviorSubject<boolean>(false);
  modalState$ = this.modalStateSource.asObservable();

  openModal() {
    this.modalStateSource.next(true);
  }

  closeModal() {
    this.modalStateSource.next(false);
  }

  responseSubject = new BehaviorSubject<paperlessBillingResponse | null>(null);
  response$ = this.responseSubject.asObservable();

  enrollmentResponseSubject =
    new BehaviorSubject<paperlessBillingEnrollmentResponse | null>(null);
  enrollmentResponse$ = this.enrollmentResponseSubject.asObservable();

  constructor(private http: HttpClient) {}

  fetchPaperlessBillingInfo() {
    this.http
      .post<paperlessBillingResponse>(
        environment.apiUrl + this.endPointBase,
        {},
        { observe: 'response' }
      )
      .subscribe({
        next: (res: HttpResponse<paperlessBillingResponse>) => {
          if (res.status === 200 && res.body) {
            this.response = res.body;
            this.responseSubject.next(res.body);
            this.complete = true;
          }
        },
      });
  }

  fetchCityStateInfo(
    formData: unknown
  ): Observable<HttpResponse<cityStateResponse>> {
    return this.http.post<cityStateResponse>(
      environment.apiUrl + this.endPointCityStateBase,
      formData,
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        observe: 'response',
      }
    );
  }

  enrollWithEmail(
    formData: unknown
  ): Observable<HttpResponse<paperlessBillingEnrollmentResponse>> {
    return this.http.post<paperlessBillingEnrollmentResponse>(
      environment.apiUrl + this.endPointEmailEnrollBase,
      formData,
      { observe: 'response' }
    );
  }

  enrollWithMobile(
    formData: unknown
  ): Observable<HttpResponse<paperlessBillingEnrollmentResponse>> {
    return this.http.post<paperlessBillingEnrollmentResponse>(
      environment.apiUrl + this.endPointPhoneEnrollBase,
      formData,
      { observe: 'response' }
    );
  }

  optOut(
    formData: unknown
  ): Observable<HttpResponse<paperlessBillingEnrollmentResponse>> {
    return this.http.post<paperlessBillingEnrollmentResponse>(
      environment.apiUrl + this.endpointUnenrollBase,
      formData,
      { observe: 'response' }
    );
  }

  getPaperlessBillingInfo() {
    return this.response; // Return data stored in the service
  }

  getEnrollmentInfo() {
    return this.enrollmentResponse;
  }
}
